import { useState } from 'react';

import { t } from 'i18next';

import { Camera, CameraResultType, CameraSource, GalleryPhoto, Photo } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';

import { useImageDelete } from '@/modules/images/api/useImageDelete.ts';
import { useImageUpload } from '@/modules/images/api/useImageUpload.ts';
import { notify } from '@/utils/notify.tsx';

export type UserPhoto = {
  filepath: string;
  photo?: string;
  webviewPath?: string;
};

export const usePhotoActions = () => {
  // const [presentAlert] = useIonAlert();

  const [photos, setPhotos] = useState<UserPhoto[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const imageUpload = useImageUpload();
  const imageDelete = useImageDelete();

  const takeCameraPhoto = async () => {
    await requestCameraGalleryPermission('camera');
    const photo = await Camera.getPhoto({
      quality: 80,
      resultType: CameraResultType.Uri,
      saveToGallery: true,
      source: CameraSource.Camera,
    });

    const uploadedFile = await onImageUpload(photo);

    if (uploadedFile) {
      const newPhotos = [uploadedFile, ...photos];
      setPhotos(newPhotos);
    }
  };

  const pickGalleryImages = async () => {
    await requestCameraGalleryPermission('photos');
    try {
      const images = await Camera.pickImages({
        limit: 3,
        quality: 80,
      });

      if (images.photos.length > 3 - photos.length) {
        notify('warning', t('offers.canNotAddManyPhotos'));
      }

      const uploadPromises = images.photos.slice(0, 3 - photos.length).map((photo) => {
        return onImageUpload(photo);
      });

      const uploadedPhotos = (await Promise.all(uploadPromises)).filter((photo) => !!photo);

      setPhotos((currentPhotos) => [...uploadedPhotos, ...currentPhotos]);
    } catch (error) {
      console.debug('Error picking images:', error);
    }
  };

  const onImageUpload = async (photo: GalleryPhoto | Photo): Promise<UserPhoto | undefined> => {
    setIsUploading(true);

    const photoResponse = await fetch(photo.webPath!);
    const photoBlob = await photoResponse.blob();

    const fileName = photo.webPath!.split('/').pop();
    const fileExtension = photoBlob.type.split('/').pop();
    const isFileExtensionHeic = ['heic', 'heif'].includes(fileExtension?.toLowerCase() || '');

    let file: File;

    if (isFileExtensionHeic) {
      const heic2any = (await import('heic2any')).default;

      const jpegBlob = (await heic2any({
        blob: photoBlob,
        quality: 0.8, // cuts the quality and size by half
        toType: 'image/jpeg',
      })) as Blob;

      file = new File([jpegBlob], `${fileName}.jpeg`, {
        type: 'image/jpeg',
      });
    } else {
      file = new File([photoBlob], `${fileName}.${fileExtension}`, { type: photoBlob.type });
    }

    const formData = new FormData();
    formData.append('file', file);

    const uploadedUrl = await imageUpload
      .mutateAsync({ file: formData })
      .then((res) => res.data)
      .catch((error) => {
        console.debug('Error uploading image:', error);
        return null;
      })
      .finally(() => {
        setIsUploading(false);
      });

    return uploadedUrl
      ? {
          filepath: `${fileName}.${fileExtension}`,
          photo: uploadedUrl,
          webviewPath: photo.webPath,
        }
      : undefined;
  };

  const requestCameraGalleryPermission = async (type: 'camera' | 'photos') => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    // const text = type === 'camera' ? t('common.camera') : t('common.photoLibrary');
    const status = await Camera.checkPermissions();
    if (status[type] !== 'granted') {
      const result = await Camera.requestPermissions({ permissions: [type] });
      if (result[type] !== 'granted') {
        // await presentAlert({
        //   buttons: [
        //     { role: 'cancel', text: t('common.close') },
        //     {
        //       handler: () =>
        //         NativeSettings.open({
        //           optionAndroid: AndroidSettings.ApplicationDetails,
        //           optionIOS: IOSSettings.App,
        //         }),
        //       text: t('common.settings'),
        //     },
        //   ],
        //   header: t('common.noAccess'),
        //   message: `${t('shop.appDoesNotHaveAccessTo')} ${text}. ${t(
        //     'shop.toAddPhotosAllowSettings',
        //   )}`,
        // });
      }
    }
  };

  const deletePhoto = (index: number) => {
    const newPhotos = photos.filter((_, i) => i !== index);
    setPhotos(newPhotos);
  };

  const deleteUploadedPhoto = async (index: number) => {
    setIsDeleting(true);
    const photo = photos[index];
    if (photo.photo) {
      await imageDelete.mutateAsync({ fileName: photo.photo }).catch(() => {
        //
      });
    }
    const newPhotos = photos.filter((_, i) => i !== index);
    setPhotos(newPhotos);
    setIsDeleting(false);
  };

  return {
    deletePhoto,
    deleteUploadedPhoto,
    isDeleting,
    isLoading: isDeleting || isUploading || imageUpload.isPending || imageDelete.isPending,
    isUploading,
    photos,
    pickGalleryImages,
    takeCameraPhoto,
  };
};
